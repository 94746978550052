.about {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 100px 0px 0px;
    margin-bottom: 100px;
    .about-image {
        img {
            min-width: 350px;
            opacity: 0.7;
        }
        padding: 0px 30px;
    }
    @media screen and (max-width: 1400px) {
        flex-direction: column;
        align-items: flex-start;
        .content {
            z-index: 1;
        }
        .about-image {
            margin-left: auto;
            opacity: 0.8;
            margin-right: 20px;
            margin-top: -350px;
            z-index: -1;
        }
        .experience {
            text-align: left;
            margin-left: 100px;
            .work-group {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 20px 40px;
                .experience-item {
                    text-align: left;
                }
                @media screen and (max-width: 650px) {
                    flex-direction: column;
                }
            }
        }
    }
    @media screen and (max-width: 1280px) {
        .about-image {
            opacity: 0.7;
            img {
                height: 400px;
                width: auto;
                min-width: 0px;
            }
        }
    }
    @media screen and (max-width: 1100px) {
        .about-image {
            margin-top: 0px;
            margin-left: 50px;
            z-index: 1;
        }
    }
    @media screen and (max-width: 1024px) {
        .experience {
            text-align: left;
            margin-left: 50px;
        }
        .experience-item {
            min-width: 200px;
            margin-bottom: 20px;
        }
        .about-image {
            margin-top: 0px;
            margin-left: 25px;
            z-index: 1;
        }
    }
    @media screen and (max-width: 1024px) {
        padding-right: 0px;
    }
    @media screen and (max-width: 650px) {
        .experience {
            margin-left: 25px;
        }
        .about-image {
            margin-left: 0px;
        }
    }
}

.experience {
    font-family: var(--font-body);
    text-align: right;
    min-width: 275px;
    .title {
        color: #EAB464;
        text-transform: uppercase;
        font-size: 24px;
        font-family: var(--font-body);
        letter-spacing: -1.9px;
        margin-bottom: 20px;
    }
    .experience-item {
        color: white;
        text-transform: uppercase;
        text-align: right;
        margin-bottom: 30px;
        .date {
            font-size: 14px;

        }
        .business {
            font-size: 24px;
            letter-spacing: -1px;
            font-weight: 600;
            margin-top: 5px;
        }
        .role {
            margin-top: 5px;
            font-size: 16px;
            font-weight: 300;
        }
    }
}